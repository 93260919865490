@import '~global/styles/variables.global.scss';
@import '../variable.scss';

.headerMobileMenu {
  padding: 0 $leftRightPadding;

  &__phone {
    @media screen and (max-width: 767px) {
      display: none;
    }

    & svg {
      cursor: pointer;

      &:hover {
        color: $logo-color;
      }
    }
  }
}

.headerMobileMenuHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  margin-left: -16px;
  margin-right: -16px;
  height: 56px;
  box-shadow: 0 1px 0 0 rgba(224, 227, 230, 0.4);

}

.headerMobileMenuTitle {
  font-size: 17px;
}

.headerMobileMenuClose {
  color: #525252;
  transition: color 0.2s;

  &:hover {
    color: var(--primary-color, #af1ebe);
  }
}

.headerMobileMenuLogo {
  display: flex;
  align-items: center;

  svg {
    max-width: 116px;
  }
}

.headerMobileMenuCity {
  display: none;
  margin-top: 16px;

  @media screen and (max-width: 767px) {
    display: block;
  }
}

.headerMobileMenuSubTitle {
  margin-top: 16px;
  height: 48px;
  font-size: 16px;
  line-height: 48px;
  color: $black;
}

.headerMobileMenuSubBlock {
  height: calc(100% - 126px);
  overflow-y: auto;
}

.headerMobileMenuBlock {
  margin-top: 20px;
  height: calc(100% - 114px);
  padding-bottom: 16px;
  overflow-y: auto;

  @media screen and (max-width: 767px) {
    height: calc(100% - 228px);
  }
}

.headerMobileMenuList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  +.headerMobileMenuList {
    margin-top: 24px;
  }
}

.headerMobileMenuListTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.headerMobileMenuContent {
  margin-top: 8px;
}

.headerMobileMenuItem {
  display: flex;
  align-items: center;
  height: 48px;
  font-size: 16px;
  font-weight: 400;
  transition: color 0.15s;
  cursor: pointer;

  span {
    margin-left: 12px;
  }

  svg:last-child {
    margin-left: auto;
    margin-right: 8px;
    height: 18px;
    width: 18px;
    transform: rotate(90deg);
  }
}

a.headerMobileMenuItem:hover {
  color: var(--primary-color, #af1ebe);
}

.preloaderWrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-bottom: 100px;
}