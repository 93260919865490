@import "~global/styles/variables.global.scss";

.citySelect {
  position: relative;
  
  @media screen and (max-width: 767px) {
    position: initial;
  }
}

.citySelectButton {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  color: $black;
  
  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
  
  svg:first-child {
    display: none;
    margin-right: 8px;
    
    @media screen and (max-width: 767px) {
      display: block;
    }
  }
  svg:last-child {
    &.open {
      transform: rotate(180deg);
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}
