.searchContainer {
  display: none;
  padding-bottom: 5px !important;

  @media screen and (max-width: 767px) {
    position: fixed;
    top: 60px;
    width: 100%;
    z-index: 107;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 0 16px 10px;
    margin-bottom: 12px;

  }
}

.mobileMargin {
  display: none;
  height: 40px;

  @media screen and (max-width: 767px) {
    display: block;
  }
}

.search {
  flex: 1;
  padding: 4px 40px 4px 20px;
  height: 40px;
  background: #f5f5f5;
  border-radius: 8px;
  border: none;
  outline: none;
  color: black;
  font-weight: 400;
  line-height: 140%;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f5f5f5 inset !important;
  }

  &:focus {
    color: black;
  }

  &::placeholder {
    opacity: 1;
    color: #a0a0a0;
  }

  @media screen and (max-width: 767px) {
    font-size: 13px;

  }
}

.searchButton {
  position: absolute;
  top: 0;
  right: 15px;
  height: 100%;
  display: flex;
  align-items: center;

  &:hover .searchIcon {
    color: #1a1a1a;
  }
}

.searchIcon {
  width: 20px;
  margin-right: 10px;
  color: #a7a7ab;
  transition: color 0.2s ease-in;
}