@import '../../../global/styles/variables.global.scss';

.categoriesList {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  column-gap: 0px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
    row-gap: 24px;
    padding-top: 0;
  }

  @media screen and (max-width: 576px) {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 10px;
    gap: 8.5px;
  }
}

.category {
  position: relative;
  display: flex;
  border-radius: 8px;
  border: 1px solid white;
  transition: 0.1s;

  &:hover {
    border: 1px solid #a0a0a0;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);

    @media screen and (max-width: 576px) {
      background-color: initial;
    }

    .categoryTitle {
      color: var(--primary-color, #af1ebe);

      @media screen and (max-width: 576px) {
        color: initial;
      }
    }
  }

  &::before {
    content: '';
    display: block;
    padding-bottom: 100%;
    border-radius: 8px;

    @media screen and (max-width: 576px) {
      display: none;
    }
  }
}

.categoryContent {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  width: 100%;
  height: 100%;
  min-height: 120px;

  @media screen and (max-width: 576px) {
    position: relative;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 20px;
  }

  @media screen and (max-width: 472px) {
    width: 120px;
  }

  @media screen and (max-width: 405px) {
    width: 110px;
  }

  @media screen and (max-width: 370px) {
    width: 100px;
  }

  @media screen and (max-width: 344px) {
    width: 90px;
  }
}

.categoryImage {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: auto;
  width: 100%;
  height: 100%;
  max-width: 180px;
  max-height: calc(100% - 46px);
  top: 12px;

  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
  }

  @media screen and (max-width: 576px) {
    top: initial;
    min-width: 33%;
    max-width: 70px;
    max-height: 70px;
  }

  @media screen and (max-width: 400px) {
    max-width: 50px;
    max-height: 50px;
  }

  @media screen and (max-width: 344px) {
    max-width: 50px;
    max-height: 50px;
  }

  svg {
    margin-top: -12px;
    max-width: 278px;
    width: 100%;
    height: auto;
    color: #b3b3b3;

    @media screen and (max-width: 576px) {
      margin-top: 0;
    }
  }
}

.categoryTitle {
  position: relative;
  line-height: initial;
  line-clamp: 2;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  @media screen and (max-width: 1140px) {
    font-size: 14px;
  }

  @media screen and (max-width: 767px) {
    font-size: 14px;
    font-weight: 600;
    line-height: 13px;
    margin-top: 0;
  }

  @media screen and (max-width: 576px) {
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 12px;
  }

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }

  @media screen and (max-width: 370px) {
    justify-content: center;
    overflow: visible;
    font-size: 10px;
  }
}


.listContainer {
  .categoriesList {
    grid-template-columns: 1fr;
    row-gap: 10px;
    gap: 8.5px;
  }

  .categoryImage {
    width: auto;
    height: auto;
    min-width: auto;
    position: static;
    top: 0;

    img {
      top: initial;
      min-width: auto;
      width: 42px !important;
      height: 42px !important;
      position: relative;
    }
  }

  .categoryContent {
    width: 100%;
    gap: 14px;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    min-height: auto;
    height: auto;
    padding: 0;
    background: none;
    position: relative;
    align-items: center;

    border-radius: 8px;
    padding: 10px 0;

    &:hover {
      background-color: $light-grey;
    }
  }

  .category::before {
    display: none;
  }

  .categoryTitle {
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    &::before {
      display: none;
    }
  }


}