@import '../../../../global/styles/variables.global.scss';
@import '../../../../global/styles/typography.scss';

.mainHeader {
  display: flex;
  align-items: center;
  padding: 24px 20px 10px;
  position: relative;
  margin: 0 auto 8px;
  max-width: 1640px;
  min-width: 280px;

  @media screen and (max-width: 1223px) {
    justify-content: space-between;
  }

  @media screen and (max-width: 767px) {
    padding-top: 18px;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 600;
  }
}

.mainHeaderMobile {
  display: flex;

  @media screen and (max-width: 767px) {
    width: 100%;
    justify-content: space-between;
  }

  &__rightSide {
    display: none;

    @media screen and (max-width: 767px) {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  &__iconWithCounter {
    position: relative;

    svg {
      width: 28px;
      height: 28px;
    }
  }

  &__cartIcon {
    height: 200px;
  }

  &__iconCounter {
    left: 15px;
    bottom: 18px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    min-height: 18px;
    min-width: 18px;
    border-radius: 50%;
    background-color: $logo-color;
    color: $white;
  }

  &__button {
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: $logo-color;
    }
  }
}

.burgerMenu {
  display: none;
  margin-right: 10px;
  color: #1a1a1a;

  &:hover {
    color: var(--primary-color, #af1ebe);
  }

  & svg {
    color: #1a1a1a;
  }

  @media screen and (max-width: 1080px) {
    display: block;
  }

  @media screen and (max-width: 767px) {
    margin: 0;
  }
}

.burgerMenuHide {
  display: block;

  @media screen and (max-width: 767px) {
    display: none;
  }

  @media screen and (min-width: 1080px) {
    display: none;
  }
}

.logo {
  display: block;

  svg {
    width: 160px;
    height: 27px;

    @media screen and (max-width: 1080px) {
      width: 116px;
      height: 22px;
    }
  }
}

.headerSearch {
  @media screen and (min-width: 1224px) {
    flex: 1;
    margin-left: 26px;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.headerButtons {
  display: flex;
  align-items: center;

  @media screen and (min-width: 1224px) {
    margin-left: 3%;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.headerButton {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  transition: color 0.2s;
  cursor: pointer;
  margin: 0px 10px 0px 10px;

  +.headerButton {
    margin-left: 16px;
  }

  &:hover {
    color: var(--primary-color, #af1ebe);
  }
}

.headerButtonBadge {
  position: absolute;
  top: 0;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background: #af1ebe;
  color: #fff;
  font-size: $smallMobileFontSize;
  line-height: inherit;
  text-align: center;
  border-radius: 50%;
  transition: color 0.2s;
}

.badgeBasket {
  right: 10px;
}

.headerCategory {
  margin: 0px -10px 0px 25px;
  width: 172px;
  height: 40px;
  display: flex;
  color: white;
  background: var(--primary-color);
  font-size: 14px;
  font-weight: 500;
  padding: 5px 25px;
  white-space: nowrap;
  border-radius: 8px;
  transition: 0.2s;
  align-items: center;

  &:hover,
  &.active {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.295)),
      var(--primary-color, #af1ebe);
  }

  svg {
    margin-right: 8px;
  }

  @media screen and (max-width: 1080px) {
    display: none;
  }

  @media screen and (max-width: 1400px) {
    font-size: 14px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.headerCategoryAll {
  font-weight: 700;
}