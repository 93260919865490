.headerWrapper {
    @media screen and (min-width: 767px) {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 999;
        background: white;
        padding-bottom: 5px;
    }

}

.mainHeader {
    @media screen and (min-width: 767px) {
        height: 150px;
        padding-top: 111px;
    }
}

.headerMenu {
    position: fixed;
    width: 100%;
    left: 0;
    z-index: 100;
    background: white;
    padding-top: 4px;

    @media screen and (max-width: 1080px) {
        display: none;
        padding-top: 0;
    }
}