.footer {
  margin-top: 50px;
  background-color: #F5F5F5;
  padding-bottom: 30px;

  @media screen and (max-width: 767px){
    background-color: white;
    padding-bottom: 180px;
  }
}
