$white: #ffffff;
$lighter-grey: #fafafa;
$light-grey: #ebebeb;
$grey-50: #909090;
$gray: #777777;
$black: #212121;

$accent: #367bf5;
$accent-70: #72a2f8;
$accent-15: #e1ebfd;

$logo-color: #af1ebe;
$logo-color-10: #efd2f2;

$error: #e73030;
$error-15: #fbe0e0;

$yellow: #ffa726;
$blue: #00b4f0;
$green: #2abc33;
$menuItemsBG: #f5f5f5;