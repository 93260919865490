.credentials {
  padding-bottom: 80px;
  display: none;

  @media screen and (max-width: 767px) {
    padding-top: 32px;
    padding-bottom: 10px;
  }
}
.copyright {
  display: none;
  font-size: 12px;
  color: #b3b3b3;
  line-height: 1.5;

  @media screen and (max-width: 1024px) {
    display: block;
    text-align: center;
  }
  @media screen and (max-width: 767px) {
    grid-row: 1;
  }
}

.social {
  display: none;
  justify-content: center;
  margin-bottom: 20px;

  @media screen and (max-width: 1024px) {
    grid-column: 4/7;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.socialItem {
  &:not(:first-child) {
    margin-left: 24px;
  }
}