.profileSidebar {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    margin-top: 16px;
  }
}

.profileSidebarItem {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 258px;

  font-size: 16px;
  padding: 12px;
  border-radius: 12px;
  transition-duration: 0.2s;
  transition-property: background-color, color;
  cursor: pointer;

  @media screen and (max-width: 979px) {
    padding: 8px;
    font-size: 14px;
  }
  @media screen and (max-width: 767px) {
    max-width: initial;
    padding: 12px 16px;
    margin-left: -16px;
    margin-right: -16px;
    font-size: 16px;
  }

  &.gray {
    color: #c6c6c6;

    .profileSidebarIcons svg {
      color: #c6c6c6;
    }
  }

  &:hover,
  &.active {
    background-color: #f2f2f2;
    color: #212121;

    svg {
      color: #212121;
    }
    .profileSidebarIcons svg {
      color: #212121;
    }
  }

  svg {
    color: #c6c6c6;
    margin-right: 16px;
    transition: color 0.2s;

    @media screen and (max-width: 979px) {
      margin-right: 8px;
    }
    @media screen and (max-width: 767px) {
      margin-right: 16px;
    }
  }
}
.profileSidebarBadge {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  padding: 0 8px;
  min-width: 24px;
  height: 24px;
  color: #fff;
  font-size: 16px;
  line-height: 1;
  background: #af1ebe;
  border-radius: 12px;

  @media screen and (max-width: 979px) {
    padding: 0 6px;
    height: 20px;
    min-width: 20px;
    font-size: 14px;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.profileSidebarIcons {
  display: none;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  @media screen and (max-width: 767px) {
    display: flex;
  }

  svg {
    display: none;
    margin: 0 0 0 16px;
    color: #212121;

    @media screen and (max-width: 767px) {
      display: block;
      margin-right: 16px;
    }
  }
}
