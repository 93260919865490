#callibri_wrap_pict_operator,
#callibri_wrap_pict_name_operator,
.callibri_wrap_pict_colorring {
  @media screen and (max-width: 767px) {
    display: none !important;
  }
}

div#callibri-module-area .callibri_chat_input_form textarea {
  font-size: 16px !important;
}