@import '../../../../../../global/styles/variables.global.scss';
@import '../../variable.scss';


.navContainer {
    display: flex;
    flex-direction: column;
    position: relative;



    &__item {
        padding: 6px $leftRightPadding;
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;


        &:hover {
            background-color: $light-grey;
        }
    }

    hr {
        border: none;
        background: linear-gradient(0deg, #EBEBEB 0%, #EBEBEB 100%), #C8C8C8;
        height: 0.5px;
    }


}

.activeLink {
    background-color: $light-grey;
    cursor: default;
}
