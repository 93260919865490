@import '~global/styles/variables.global.scss';

.searchContainer {
  position: relative;
  display: flex;
  margin-left: 15px;

}

.search {
  flex: 1;
  padding: 4px 40px 4px 20px;
  height: 40px;
  background: linear-gradient(0deg, #f5f5f5, #f5f5f5), #ffffff;
  border-radius: 8px;
  border: none;
  outline: none;
  color: black;
  font-weight: 400;
  transition: 0.1s;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f2f2f2 inset !important;
  }

  &::placeholder {
    opacity: 1;
  }

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
}

.searchButton {
  position: absolute;
  top: 0;
  right: 15px;
  height: 100%;
  width: 80px;
  margin-right: -15px;
  align-items: center;
  justify-content: center;
  border-radius: 0px 8px 8px 0px;
}

.searchIcon {
  padding-top: 2px;
  color: #a1a1a1;
  transition: color 0.1s ease-in;

  &:hover {
    color: black;
  }
}