.inlineModal {
  max-width: 470px;
  width: 100%;
  background: #fff;
  box-shadow: 0px 8px 32px rgba(138, 118, 92, 0.16);
  border-radius: 8px;
  overflow: hidden;
  margin: auto;
}

.inlineModalContent {
  padding: 32px 24px 40px;
}

.inlineModalTitle {
  margin-bottom: 24px;

  &.wide {
    margin-top: 8px;
    font-size: 36px;
    font-weight: 500;
    line-height: initial;

    @media screen and (max-width: 767px) {
      font-size: 24px;
    }
  }
}

.inlineModalItem {
  margin-top: 24px;

  + .inlineModalItem {
    margin-top: 20px;
  }
}

.inlineModal .inlineModalText {
  font-size: 16px;
  line-height: 24px;
}

.inlineModalLink {
  color: var(--primary-color, #af1ebe);
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.inlineModalButton {
  margin-top: 40px;
}

.inlineModalSubContent {
  padding: 40px 24px;
  background: #fafafa;
}
.inlineModalSubContentText {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
