@import "~global/styles/variables.global.scss";

.preloaderWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
}

.preloader {
    display: inline-block;
    position: relative;
    width: 66px;
    height: 66px;
}

.preloader div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 52px;
    height: 52px;
    margin: 6px;
    border: 6px solid $light-grey;
    border-radius: 50%;
    animation: preloader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $light-grey transparent transparent transparent;
}
.preloader div:nth-child(1) {
    animation-delay: -0.45s;
}
.preloader div:nth-child(2) {
    animation-delay: -0.3s;
}
.preloader div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes preloader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
