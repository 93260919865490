.modalLayout {
  position: fixed;
  display: flex;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(33, 33, 33, 0.7);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1000;
}

.modal {
  position: relative;
  margin: auto;
  max-width: 470px;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;

  &.float {
    @media screen and (max-width: 767px) {
      margin-bottom: 100px;
    }
  }

  &.wide {
    max-width: 946px;
  }
}

.modalClose {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}
