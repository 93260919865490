.mainFooter {
  margin-bottom: -150px;
  padding: 68px 0 63px;

  @media screen and (max-width: 1024px) {
    padding: 25px 0 60px 0;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 0;
    padding: 0;
  }

  .link {
    color: #af1ebe;

    &:hover {
      text-decoration: underline;
    }
  }
}

.mainFooterContent {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 24px;
  row-gap: 34px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.mainFooterContentPhone {
  display: none;

  @media screen and (max-width: 767px) {
    display: block;
  }
}

.mainFooterColumn {
  &:nth-child(1) {
    @media screen and (max-width: 1024px) {
      grid-row: 1;
    }
  }

  &:nth-child(5) {
    grid-column: 4/5;

    @media screen and (max-width: 1024px) {
      grid-column: 4;
    }
  }
}

.mainFooterColumnTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: initial;
  margin-bottom: 14px;
}

.mainFooterColumnItem {
  @media screen and (max-width: 767px) {
    margin-bottom: 16px;
  }

  + .mainFooterColumnItem {
    margin-top: 14px;
    font-size: 14px;

    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }
}

.mainFooterColumnLink {
  font-size: 16px;

  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
  &:hover {
    color: var(--primary-color, #af1ebe);
  }
}

.credentials {
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    margin-top: 0;
    margin-left: 15px;
  }

  a,
  div {
    color: #a0a0a0;
    font-size: 14px;
    margin-top: 30px;
  }
}
