@import '~global/styles/variables.global.scss';

.headerMobileMenu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: #fff;
    transition: transform 0.2s;
    z-index: 650;
    transform: translate(-100%, 0);
    overflow-y: auto;



    &.active {
        transform: translate(0, 0);
    }

    @media screen and (max-width: 1080px) {
        display: block;
    }

    @media screen and (max-width: 767px) {
        width: 100%;
    }
}