@import '../../../global/styles/typography.scss';

.contactsBlock {
  opacity: 0;
  bottom: 50px;
  left: 0;
  right: 0;
  background-color: #f5f5f5;

  @media screen and (max-width: 767px) {
    opacity: 1;
    display: block;
    margin-top: 10px;
    padding-top: 24px !important;
    padding-bottom: 24px !important;
    background-color: #f5f5f5;
  }

  a {
    margin-top: 6px;
    font-size: 13px;
    font-weight: 600;
    color: black;

    &:hover {
      transition: 0.2s;
      color: var(--primary-color, #af1ebe);
    }
  }
}

.contactsItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  +.contactsItem {
    margin-top: 24px;
  }
}

.contactsTitle {
  display: block !important;
  font-weight: 500;
}

.contactsWorkTime {
  font-size: $smallMobileFontSize;
  color: #b3b3b3;
}

.contactsEmail {
  font-size: $smallMobileFontSize;
  margin-top: 6px;
  color: black;
  font-weight: 600;

  &:hover {
    transition: 0.2s;
    color: var(--primary-color, #af1ebe);
  }
}