@import '~global/styles/variables.global.scss';

.BannerBlockContainer {
  display: none;
  padding: 20px;
  height: 100%;
  background-color: var(--primary-color);
  color: white;
  text-align: left;

  @media screen and (max-width: 767px) {
    display: block;
  }
}

.BannerBlockHeader {
  font-weight: 700;
  font-size: 24px;
}

.BannerBlockText {
  width: 240px;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 15px;
}

.BannerBlockContainerWidth {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 135px;
  background-color: #911ba0;

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
  }

  .workTime {
    font-size: 14px;
    display: block;
  }

  .BannerBlockTextWidth {
    text-align: center;
    width: 380px;
    font-size: 16px;
    font-weight: 400;
    color: white;
    line-height: 24px;

    a {
      font-weight: 600;
    }
  }

  .mailInfo {
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }
}
