.profileMobileMenu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 16px;
  background: #fff;
  transition: transform 0.2s;
  z-index: 650;
  transform: translate(-100%, 0);

  &.active {
    transform: translate(0, 0);
  }

  @media screen and (max-width: 767px) {
    display: block;
  }
}

.profileMobileMenuHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  margin-left: -16px;
  margin-right: -16px;
  height: 56px;
  box-shadow: 0 1px 0 0 rgba(224, 227, 230, 0.4);
}

.profileMobileMenuClose {
  color: #525252;
  transition: color 0.2s;

  &:hover {
    color: var(--primary-color, #af1ebe);
  }
}
.profileMobileMenuLogo {
  display: flex;
  align-items: center;

  svg {
    max-width: 116px;
  }
}
.profileMobileMenuPhone {
  display: flex;
  align-items: center;
  transition: color 0.2s;

  &:hover {
    color: var(--primary-color, #af1ebe);
  }
}

.profileMobileMenuTitle {
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 24px;
  letter-spacing: 0;
  font-weight: 500;
}
