@import '../variable.scss';

.mobileMenu {
    position: relative;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px $leftRightPadding;

    }

    &__categoriesTitle {
        padding: 24px $leftRightPadding 10px;
        font-size: 18px;
        font-weight: 700;
        line-height: 18px;
    }

    &__register {
        position: fixed;
        box-shadow: 4px 0px 8px 1px rgba(0, 0, 0, 0.16);
        padding: 16px;
        background-color: #FFF;
        width: 100%;
        bottom: 0;
        z-index: 1000;

        & button {
            width: 100%;
        }
    }

    & &__categories {
        margin-bottom: 80px;
    }

}