.collapsingMenu {
  position: absolute;
  left: 0;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 16px;
  width: 100vw;
  z-index: 100;
  min-height: 140px;

  @media screen and (max-width: 1140px) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.collapsingMenuIn {
  border: none;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  background: white;
  position: absolute;
  z-index: 101;
  left: -9000px;
  right: -9000px;
  top: 0;
  width: auto;
  height: 100%;
}

.headerMenuContent {
  position: relative;
  z-index: 102;
  display: flex;
  width: 100%;

  &:not(:last-child) {
    padding-bottom: 40px;
  }
}

.rootMenu {
  display: flex;
  flex-direction: column;
  width: 34%;
  margin-right: 40px;
  max-width: 274px;
  background-color: #f5f5f5;
  border-radius: 8px;
  overflow-y: auto;

  + .subMenu {
    padding-left: 16px;
  }
}

.rootMenuItemDivided {
  position: relative;
  padding-top: 12px;
  margin-top: 12px;

  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 22px;
    width: calc(100% - 44px);
    border-top: 1px solid #c4c4c4;
  }
}

.rootMenuLink {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  border-radius: 8px;
  transition: color 0.2s, background-color 0.2s;

  &:hover,
  &.active {
    color: var(--primary-color, #af1ebe);
    background-color: #ededed;
  }

  span {
    margin-left: 12px;
  }
}

.collapsingMenuBody {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-content: flex-start;
  max-height: 800px;
  padding-left: 34px;

  &.big {
    max-height: 800px;
    padding-left: 0;
  }

  &.sideMenu {
    flex-direction: column;
    flex-wrap: wrap;
  }
}

.collapsingMenuItem {
  height: 100%;
}

.collapsingMenuBlock {
  margin-right: 60px;
  margin-bottom: 12px;
  min-width: 200px;
}

.collapsingMenuBlockTitle {
  display: flex;
  margin-bottom: 8px;
  transition: color 0.15s;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  column-gap: 10px;
}

a.collapsingMenuBlockTitle:hover {
  color: var(--primary-color, #af1ebe);
}

.collapsingMenuBlockItems {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.collapsingMenuBlockItem {
  max-width: max-content;
  transition: color 0.15s;
  margin-bottom: 8px;

  font-size: 14px;
  line-height: 20px;

  &:hover {
    color: var(--primary-color, #af1ebe);
  }

  + .subMenuItem {
    margin-top: 24px;
  }
}

.headerPreloaderWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
