.socialsList {
  display: none;
  margin: 8px 0 24px;

  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: center;
  }
}
.socialsListItem {
  + .socialsListItem {
    margin-left: 32px;
  }
}

.credentials {
  display: flex;
  flex-direction: column;
  align-items: center;

  a,
  div {
    color: #a0a0a0;
    font-size: 12px;
    margin-top: 30px;
  }
}
