.menuContainer {
  display: flex;
  position: relative;
  margin: 0 auto;
  max-width: 1640px;
  min-width: 280px;

  @media screen and (max-width: 1080px) {
    padding-top: 0;
    display: none;
  }
}
.menu {
  display: flex;
  align-items: last baseline;
  margin-left: 100px;
  padding: 0 6px;
  background-color: white;
  border-radius: 8px;
  
  @media screen and (max-width: 1264px) {
    justify-content: flex-start;
  }

  @media screen and (max-width: 1333px) {
    align-items: normal;
  }
}

.headerMenuItems {
  display: flex;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  align-items: center;

  &::-webkit-scrollbar {
    display: none;
  }
}
.headerCity {
  padding: 3px;
  margin-left: 20px;
  display: flex;

  @media screen and (max-width: 1400px) {
    font-size: 13px;
  }

  > div > button {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

.headerCategory {
  margin-bottom: 5px;
  margin-right: 5px;
  display: flex;
  color: black;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 12px;
  white-space: nowrap;
  border-radius: 8px;
  
  @media screen and (max-width: 1300px) {
    margin-right: 0px;
    padding: 3px 10px;
  }

  @media screen and (max-width: 1156px) {
    padding: 3px 7px;
  }
  &:hover,
  &.active {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), black;
    color: white;
  }

  svg {
    margin-right: 8px;
  }

  @media screen and (max-width: 1400px) {
    font-size: 13px;
    overflow: hidden;
  }
}

.headerMobileMenuPhone {
  display: none;
  position: absolute;
  margin-right: 20px;
  right: 0;

  @media screen and (max-width: 1590px) {
    display: block;
  }

  @media screen and (max-width: 1200px) {
    display: none;
  }
}
.phoneWraper {
  display: flex;
  position: absolute;
  right: 0;
  margin-right: 25px;
  white-space: nowrap;
  padding: 5px;

  @media screen and (max-width: 1590px) {
    display: none;
  }
}
.phone {
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  color: var(--primary-color);

  @media screen and (max-width: 1576px) {
    font-size: 13px;
  }
}
.phoneLink {
  font-weight: 500;
  margin-right: 5px;
  max-width: max-content;
  font-size: 14px;
  color: black;
  cursor: pointer;

  @media screen and (max-width: 1576px) {
    font-size: 13px;
  }

  &:hover {
    color: var(--primary-color);
  }
}
