.smallHeader {
  background-color: #f2f2f2;

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.smallHeaderNav {
  display: flex;
  align-items: center;
  height: 24px;
}

.smallHeaderItem {
  font-size: 12px;
  color: #939393;
  transition: color 0.15s ease-in;

  &:hover {
    color: var(--primary-color, #af1ebe);
  }

  + .smallHeaderItem {
    margin-left: 24px;
  }
}
