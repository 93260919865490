@import '../../../global/styles/typography.scss';

.subscription {
  margin-top: 150px;
  margin-bottom: 200px;

  @media screen and (max-width: 967px) {
    margin: 53px 15px 50px 15px;
  }
}

.subscriptionHeader {
  font-size: 36px;
  text-align: center;
  line-height: 42px;
  font-weight: 600;
  margin-top: 34px;
  margin-bottom: 12px;

  @media screen and (max-width: 967px) {
    font-size: 24px;
    line-height: 18px;
    font-weight: 700;
    margin-top: 24px;
    margin-bottom: 14px;
  }

  @media screen and (max-width: 767px) {
    text-align: left;
  }
}

.subscriptionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 8px;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    padding: 20px;
    height: 256px;
    align-items: start;
    margin-bottom: 50px;
  }

  @media screen and (max-width: 547px) {
    margin-top: 0px;
  }
}

.subscriptionContent {
  margin-bottom: 22px;
  text-align: right;
  line-height: initial;

  @media screen and (max-width: 967px) {
    margin-bottom: 0;
    text-align: center;
  }
}

.subscriptionTitle {
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;

  @media screen and (max-width: 901px) {
    margin-top: 0;
    font-size: $smallMobileFontSize;
    line-height: 16px;
  }
}

.subscriptionInput {
  position: relative;
  display: flex;
  justify-content: center;

  input {
    font-size: 16px;
    width: 664px;
    height: 68px;
    padding: 20px 0 5px 25px;
    background-color: white;
    border-radius: 68px;
    transition: 0.3s;

    @media screen and (max-width: 967px) {
      width: 664px;
      height: 48px;
    }
  }

  label {
    margin: 10px 0 0 15px;

    @media screen and (max-width: 967px) {
      margin: 0 0 0 10px;
    }
  }

  .subscriptInputButton {
    position: absolute;
    top: 0.2em;
    right: -168px;
    height: 60px;
    width: 235px;
    border-radius: 52px;
    background: linear-gradient(270deg, #b477fa 7.56%, #af1ebe 100%);
    font-size: 20px;
    font-weight: 600;
    color: white;
    transition: 0.3s;

    &:hover {
      background: linear-gradient(#bc8cee 0%, #b541c2 100%);
      box-shadow: rgba(177, 36, 170, 0.534) 0px 4px 6px;
    }

    @media screen and (max-width: 967px) {
      font-size: 16px;
      width: 200px;
      height: 40px;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

.subscriptionLink {
  margin-left: 5px;
  color: var(--primary-color, #af1ebe);
  transition: color 0.15s ease-in;

  &:hover {
    color: #9b1ba8;
  }
}

.subscriptionInputMobile {
  display: none;

  input {
    background-color: white;
    border: 0.5px solid #c8c8c8;
    width: 100%;
    min-height: 100%;
    height: 36px;
  }

  label {
    top: 8px;
  }

  span {
    font-size: 13px;
  }
}

@media screen and (max-width: 767px) {
  .subscriptionInputMobile {
    margin-top: 14px;
    width: 100%;
    display: block;
  }
}

.subscriptionInput {
  width: 320px;
  margin: 8px 24px 0;
  border-radius: 8px;

  @media screen and (max-width: 767px) {
    display: none;
    width: 100%;
    margin: 16px 0 0;
  }
}

.subscriptButton {
  margin-top: 10px;
  width: 113px;
  height: 30px;
  border-radius: 22px;
  background-color: var(--primary-color);
  color: white;
  font-size: $smallMobileFontSize;
  font-weight: 600;
}

.subscriptionConditionText {
  display: block;
  width: 441px;
  text-align: center;
  margin: 16px 0 45px 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  @media screen and (max-width: 767px) {
    overflow: hidden;
    width: 291px;
    text-align: left;
    margin: 16px 0 17px 0;
    line-height: 14px;
  }
}