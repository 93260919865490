.accordeonBlock {
  position: relative;
}
.accordeon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px;
  cursor: pointer;
}
.accordeonTitle {
  font-size: 14px;
  font-weight: 600;
}
.accordeonArrow {
  display: flex;
  transition: transform 0.4s;

  svg {
    height: 18px;
    width: 18px;
  }
}
.accordeonArrowActive {
  transform: rotate(-180deg);
}

.accordeonPanel {
  padding: 0 16px;
  overflow: hidden;
  height: 0;
  transition: height 0.4s ease-out;
}
